import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import BodyText from '../atoms/BodyText';
import ContentLink from '../atoms/ContentLink';
import Title from '../atoms/Title';
import {
    NewsArticleBlogFragment_nextArticle,
    NewsArticleMediumFragment_nextArticle,
} from '../../entities/operationResults';
import { SiteContext } from '../../contexts/SiteContext';

interface Props {
    nextArticle: NewsArticleMediumFragment_nextArticle | NewsArticleBlogFragment_nextArticle;
}

const NextBubble: FC<Props> = ({ nextArticle }) => {
    const site = useContext(SiteContext);
    const title = nextArticle.title;
    const uri = nextArticle.uri;

    return (
        <BallWrapper>
            {title && (
                <Wrapper>
                    <NextTitle variant={'small'}>Volgende Artikel:</NextTitle>
                    <Title variant={'medium'}>{title}</Title>
                </Wrapper>
            )}
            {uri && (
                <Wrapper>
                    <ContentLink to={site ? `/${site}/${uri}/` : `/${uri}/`}>Lees verder</ContentLink>
                </Wrapper>
            )}
        </BallWrapper>
    );
};

export default NextBubble;

const Wrapper = styled.div`
    display: block;
`;

const BallWrapper = styled.article`
    z-index: 1;
    width: 50rem;
`;

const NextTitle = styled(BodyText)`
    font-weight: 400 !important;
    margin: 0;
`;
