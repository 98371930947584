import React, { FC } from 'react';
import { graphql } from 'gatsby';
import NewsArticle from './organisms/NewsArticle';
import { NewsArticleMediumFragment, NewsArticleMediumQuery } from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';

export const query = graphql`
    query NewsArticleMediumQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...NewsArticleMediumFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment NewsArticleMediumFragment on Craft_NewsArticleArticleMedium {
        id
        type {
            name
        }
        uri
        title
        postDate
        teamMemberEntry {
            ... on Craft_TeamMember {
                firstName
                lastName
                teamMemberImage {
                    ...ArticleAuthor
                }
            }
        }
        coverImage {
            title
            url
            url500: url(fit: { width: 500, quality: 100 })
            url1000: url(fit: { width: 1000, quality: 100 })
            url1500: url(fit: { width: 1500, quality: 100 })
            url2000: url(fit: { width: 2000, quality: 100 })
            width
            height
        }
        intro {
            subText {
                content
            }
            text {
                content
            }
        }
        nextArticle {
            uri
            title
        }
        urlLink {
            customText
            url
        }
        readingtime
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    data: NewsArticleMediumQuery;
}

const NewsArticleMedium: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as NewsArticleMediumFragment;
    return <NewsArticle entry={entry} />;
};

export default withDefaultTransition(NewsArticleMedium);
