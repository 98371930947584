import React, { FC } from 'react';
import Meta from '../atoms/Meta';
import styled from 'styled-components';
import Footer from './Footer';
import {
    NewsArticleBlogFragment,
    NewsArticleBlogFragment_teamMemberEntry_Craft_TeamMember,
    NewsArticleMediumFragment,
    NewsArticleMediumFragment_teamMemberEntry_Craft_TeamMember,
} from '../../entities/operationResults';
import NextBubble from './NextBubble';
import { removeTags } from '../../utils/removeTags';
import Section, { lightTheme } from '../ds/layout/LayoutSection';
import cssCalcBetweenViewport from '../../utils/cssCalcBetweenViewport';
import LayoutMain from '../ds/layout/LayoutMain';
import { H1 } from '../ds/primitive/Typography';
import { Section as DeprecatedSection } from '../atoms/Section';
import ContentArticle from '../ds/content/ContentArticle';
import theme from '../../constants/theme';
// import Interweave from 'interweave';
// import Image from '../ds/content/Image';
import AuthorInfo from '../molecules/AuthorInfo';
import timestampToDate from '../../utils/timestampToDate';
import RichContent, { ArticleSectionContainer, Lead } from '../RichContent';
import Interweave from 'interweave';
import Image from '../ds/content/Image';

interface Props {
    entry: NewsArticleBlogFragment | NewsArticleMediumFragment;
}

const NewsArticleNew: FC<Props> = ({ entry }) => {
    const type = entry.type && entry.type.name;
    const title = entry.title;
    const meta = entry.seo && entry.seo[0];
    const coverImage = entry.coverImage!;
    const date = entry.postDate && timestampToDate(entry.postDate);
    // TODO: Figure out why teamMemberEntry is possibly of type Craft_AboutUs, with typename
    const teamMember = entry.teamMemberEntry?.[0] as
        | NewsArticleMediumFragment_teamMemberEntry_Craft_TeamMember
        | NewsArticleBlogFragment_teamMemberEntry_Craft_TeamMember;

    const intro = entry.intro && entry.intro[0];
    const introText = intro && intro.text && intro.text.content;
    const introSubText = intro?.subText?.content;
    const description = `${introText ? introText : ''}\n${introSubText ? introSubText : ''}`;

    let readingTime;
    let urlLink;
    if (entry.__typename !== 'Craft_NewsArticleArticleBlog') {
        readingTime = entry.readingtime;
        urlLink = entry.urlLink;
    }
    const nextArticle = entry.nextArticle && entry.nextArticle[0];
    const footer = entry.directContact && entry.directContact[0];

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={meta && meta.seoDescription ? meta.seoDescription : removeTags(description)}
                image={meta && meta.seoImage && meta.seoImage.length > 0 ? meta.seoImage : coverImage}
            />
            <Header theme={lightTheme} level={1} padding>
                <LayoutMain>
                    <MainTitle variant="large">{title}</MainTitle>
                    {date && teamMember && description && (
                        <AuthorInfo
                            type={type}
                            author={teamMember}
                            date={date}
                            description={description}
                            readingTime={readingTime}
                        />
                    )}
                </LayoutMain>
            </Header>
            {entry.__typename === 'Craft_NewsArticleArticleBlog' && entry.richContent ? (
                <RichContent contents={entry.richContent} sourceId={entry.id} layoutSectionProps={{ level: 0 }} />
            ) : (
                <ArticleSectionContainer level={0} padding hasIntro>
                    <LayoutMain>
                        <ContentArticle>
                            <Lead variant="huge" as="div">
                                <Interweave noWrap content={introText} />
                            </Lead>
                            {entry.__typename === 'Craft_NewsArticleArticleMedium' && (
                                <>
                                    <Image
                                        ratio={
                                            parseInt(coverImage[0]!.width || '0', 10) /
                                            parseInt(coverImage[0]!.height || '0', 10)
                                        }
                                        srcSet={`
                                        ${coverImage[0]!.url500} 500w,
                                        ${coverImage[0]!.url1000} 1000w,
                                        ${coverImage[0]!.url1500} 1500w,
                                        ${coverImage[0]!.url2000} 2000w
                                    `}
                                        src={coverImage[0]!.url2000 || undefined}
                                        alt={coverImage[0]!.title || undefined}
                                        vertical={false}
                                    />
                                    <Interweave noWrap content={introSubText} />
                                    {urlLink?.url && (
                                        <a href={urlLink.url} target="_blank">
                                            {urlLink.customText || 'Lees dit artikel op Medium'}
                                        </a>
                                    )}
                                </>
                            )}
                        </ContentArticle>
                    </LayoutMain>
                </ArticleSectionContainer>
            )}
            {/* todo: replace with relevant article component */}
            {nextArticle && (
                <NextSection level={0} padding={false}>
                    <NextBubble nextArticle={nextArticle} />
                </NextSection>
            )}
            <DeprecatedSection variant="footer" topMargin>
                {footer && <Footer entryId={entry.id} data={footer} />}
            </DeprecatedSection>
        </>
    );
};

const MainTitle = styled(H1)`
    margin-bottom: 0.5em;
`;

const Header = styled(Section)`
    @media screen and (${theme.mediaQueries.horizontal}) {
        width: ${cssCalcBetweenViewport(560, 960)};
    }
`;

const NextSection = styled(Section)`
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4.8rem;

    @media screen and (${theme.mediaQueries.horizontal}) {
        margin-bottom: 0;
    }
`;

// const Lead = styled(Body)`
//     & > *:first-child {
//         margin: 0;
//     }

//     @media screen and (${theme.mediaQueries.horizontal}) {
//         margin-left: ${cssCalcBetweenViewport(-80, -192)};
//     }
// `;

export default NewsArticleNew;
